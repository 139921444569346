form {
  text-align: right;
}
p {
  margin: 0;
}

@media screen and (min-width: 993px) {
  .form-container {
    direction: rtl;
    width: 90%;
    margin: auto;
    text-align: right;
    margin-top: 13px;
    .form-div {
      direction: rtl;
      background-color: var(--color-primary-2);
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 15px;
      .submit-button {
        border: none;
        cursor: pointer;
        background-color: var(--color-primary);
        color: var(--text-color);
        height: 50px;
        border-radius: 30px;
        width: 50%;
        font-size: 20px;
        &:hover {
          background-color: var(--color-primary-hover);
        }
      }
    }
    .fill-form {
      text-decoration: underline;
      text-underline-offset: 0.7ex;
      font-size: 20px;
    }
    .must-text {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 992px) {
  .form-container {
    width: 94%;
    direction: rtl;
    margin: auto;
    text-align: right;
    margin-top: 13px;
    .form-div {
      direction: rtl;
      background-color: var(--color-primary-2);
      margin: auto;
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 15px;
      .form-header {
        margin-bottom: 60px;
        text-align: right;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: var(--color-primary);
      }
      .submit-button {
        border: none;
        cursor: pointer;
        background-color: var(--color-primary);
        color: var(--text-color);
        height: 35px;
        border-radius: 30px;
        width: 75%;
        font-size: 16px;
      }
    }
    .slices-line {
      list-style: none;
      border: solid #00567a;
      border-width: 1px 0;
      margin: 0;
      padding: 1.25rem 0;
    }
    .fill-form {
      text-decoration: underline;
      text-underline-offset: 0.7ex;
      font-size: 14px;
    }
    .must-text {
      font-size: 11px;
    }
  }
}

.form-submitted-successfully {
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #01a03b;
  img {
    width: 50px;
    height: 50px;
  }
}

.submit {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  .invalid-feedback {
    font-weight: bold;
    font-family: sans-serif;
  }
  .loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid var(--color-primary);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1.3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
