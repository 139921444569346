.footer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.line {
  width: 95%;
  background-color: #35786e;
  height: 1px;
  margin: auto;
}
.footer-statements {
  text-align: right;
  ol {
    font-size: 12px;
    padding-right: 0;
    width: 87%;
    margin: auto;
  }
  .accessibility {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 90%;
    margin: auto;
    font-size: 12px;
    margin-bottom: 20px;
    img {
      height: 40px;
    }
  }
}

@media screen and (min-width: 993px) {
  ol {
    font-size: 12px;
    padding-right: 0;
    width: 90%;
    margin: auto;
  }
}
