@font-face {
  font-family: "Afek-Regular";
  src: url("./assets/fonts/afek-regular-aaa.otf");
}

:root {
  --color-primary: #00567a;
  --color-primary-hover:rgb(0, 63, 90);
  --color-primary-2: #f9f9f9;
  --color-secondary-1: #e91e76;
  --text-color: white;
}

body {
  margin: 0;
  direction: rtl;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Afek-Regular";
  font-size: 14px;
  line-height: 1.625;
  color: var(--color-primary);
  @media screen and (min-width: 993px) {
    font-size: 20px;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.invalid-feedback {
  color: #fb3449;
  font-size: 10px;
  font-family: "Afek-Regular";
  @media screen and (min-width: 993px) {
    font-size: 15px;
    font-family: "Afek-Regular";
  }
}

label {
  display: block;
  align-items: center;
  color: var(--color-primary);
}
button {
  font-family: "Afek-Regular";
}
.must-text {
  font-size: 11px;
  line-height: 1.7;
  opacity: 0.8;
}

.flex {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.center {
  align-items: center;
}
