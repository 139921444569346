.page-text {
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 10px;
  margin: auto;
  width: 90%;
  margin-top: 40px;
  @media screen and (min-width: 993px) {
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: 10px;
    margin: auto;
    margin-top: 40px;
  }
  a {
    color: var(--color-primary);
  }
}
h2 {
  font-size: 20px;
  line-height: 1.625;
}
.title {
  text-decoration: underline;
  text-underline-offset: 0.5ex;
}
h1 {
  font-size: 30px;
  .header-text {
    text-decoration: underline;
    text-underline-offset: 0.7ex;
  }
  sup {
    margin-right: 3px;
  }
  @media screen and (max-width: 992px) {
    font-size: 20px;
    margin: 10px;
  }
}
ul {
  padding-right: 25px;
  margin-block: 5px;
}
sup {
  vertical-align: super;
  font-size: smaller;
}
