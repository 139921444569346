.form-element {
  margin-top: 10px;
  margin-bottom: 35px;
  .form-input {
    display: block;
    width: 60%;
    height: 3vh;
    background-color: var(--color-primary-2);
    border: none;
    border-bottom: 1px solid var(--color-primary);
    font-family: sans-serif;
    font-size: 20px;
    color: var(--color-primary);
    text-align: right;
    @media screen and (max-width: 992px) {
      width: 100%;
      font-size: 14px;
  }
}
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
}
